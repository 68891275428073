.twoFactor-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.twoFactor-header ol {
    text-align: left;
}

.twoFactor-header li {
    display: list-item;
}
