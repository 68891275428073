.search-app {
    text-align: center;
    min-height: 92.99vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff; 
    color: white; 
    font-family: 'Arial', sans-serif; 
  }
  
  .search-app h1 {
    font-family: 'DM Sans', sans-serif; 
    font-size: 48px;
    margin-bottom: 20px; 
    color:#000000;
  }
  
  .totalRefund-table button {
    background-color:transparent;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: black;
    border: none;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #4c51bf;
  }
  
  .totalRefund-table {
    color: #000000;
  }
  
  .totalRefund-table tr {
    color: #000000;
  }

  tr:nth-child(even) {background-color: #afaeae}

  .totalRefund-table td {
    color: #000000;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    table-layout:fixed;
  }

  .taxpayer-input {
    margin: 20px;
  }
  
  .taxpayer-input input {
    margin-right: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 300px;
  }

  .taxpayer-input a { /*change this to be only for certain a not all a*/
    color: #000000;
    font-weight: bold;
    width: 60%;
  }
  
  /*
  .taxpayer-input button {
    margin-right: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #122754;
    color: white;
    border: 2px solid;
    border-color: #122754;
    transition: color background-color 0.3s;
  }
  
  .taxpayer-input button:hover {
    background-color: #e0e0e0;
    color: #122754;
  }*/

 