.admin-app {
    text-align: center;
    min-height: 92.99vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff; 
    color: white; 
    font-family: 'Arial', sans-serif; 
  }
  
  .admin-app h1 {
    font-family: 'DM Sans', sans-serif; 
    font-size: 48px;
    margin-bottom: 20px; 
    color:#000000;
  }
  
  .activity-table button {
    background-color:transparent;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: black;
    border: none;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #4c51bf;
  }
  
  .activity-table {
    color: #000000;
  }
  
  .activity-table tr {
    color: #000000;
  }

  tr:nth-child(even) {background-color: #afaeae}

  .activity-table td {
    color: #000000;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    table-layout:fixed;
  }

  .admin-input {
    margin: 20px;
  }
  
  .admin-input input {
    margin-right: 10px;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 80px;
  }

  .admin-input a { /*change this to be only for certain a not all a*/
    color: #000000;
    font-weight: bold;
    width: 60%;
  }

 