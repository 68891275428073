.credentials {
    text-align: center;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background: #eeeeee; 
    color: rgb(0, 0, 0); 
    font-family: 'Arial', sans-serif; 
  }

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto; 
    margin-right: auto;    
    padding: 10px;
    width: 50%;
  }

  .left-side  h1{
    font-family: 'DM Sans', sans-serif; 
    font-size: 68px;
    margin-bottom: 20px; 
  }

  .left-side  h2{
    font-family: 'DM Sans', sans-serif; 
    font-size: 48px;
    margin-bottom: 20px; 
  }

  .left-side img{
    width: 50%;
  }
  
  .credentials-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto; 
    margin-right: auto;    
    padding: 10px;
    border-radius: 16px;
    background-color: #e0e0e0;
    width: 20%;
  }

  .credentials-input h1 {
    font-family: 'Comic Sans', sans-serif; 
    font-size: 25px;
    margin-bottom: 20px; 
  }
  
  .credentials-input input {
    margin: 8px 0;
    padding: 12px 20px;
    font-size: 16px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
  }
  
  .credentials-input button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #122754;
    color: white;
    border: 2px solid;
    border-color: #122754;
    transition: color background-color 0.3s;
  }
  
  .credentials-input button:hover {
    background-color: #e0e0e0;
    color: #122754;
  }
  