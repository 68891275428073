.app {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; 
  font-family: 'Arial', sans-serif; 
}

h1 {
  font-family: 'DM Sans', sans-serif; 
  font-size: 48px;
  margin-bottom: 20px; 
  text-align: center;
}

p {
  color: #000000;
  font-weight: bold;
  width: 75%;
}

button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #122754;
  color: white;
  border: 2px solid;
  border-color: #122754;
  transition: color background-color 0.3s;
}

button:hover {
  background-color: #e0e0e0;
  color: #122754;
}

select {
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  /*width: 100% 230px;*/
}