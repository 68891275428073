.taxpayer-app {
  text-align: center;
  min-height: 92.99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff; 
  color: white; 
  font-family: 'Arial', sans-serif; 
}

.taxpayer-all {
  text-align: center;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  color: white; 
  /*background-color: #e0e0e0; */
  font-family: 'Arial', sans-serif; 
  padding: 1%;
}

div.taxpayer-app h1 {
  color: black;
  font-family: 'DM Sans', sans-serif; 
  font-size: 48px;
  margin-bottom: 20px; 
}

.taxpayer-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: #eeeeee; 
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.home-app {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.home-app p {
  text-align: center;
}

.taxpayer-info {
  text-align: center;
}
  
ul {
  color: black;
  /*background-color: rgba(30, 38, 114, 0.363);
  border: 1px solid rgba(255, 255, 255, 0.2);*/
  list-style-type: none;
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

li {
  padding: 7.5px;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.refund-list {
  color: black;
  list-style-type: none;
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
}

/*ul.refund-list li {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.officer-list {
  color: black;
  list-style-type: none;
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
}

ul.officer-list li {
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}*/

.middle-div, .middle-div2, .middle-div3 {
  background-color:black;
  height: 2px;
  width: 150%;
}

.taxpayer-all p {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  font-size: 20px;
}

.taxpayer-header p {
  width: 75%;
}

.taxpayer-app div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}